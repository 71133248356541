.html,body{
    width: 100%;
    height: 100%;
    background: white;
    padding: 0;
    margin: auto;
}

.container_err{
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    margin: 0;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.err_main{
    background-color:white; 
    width:100vh;
    height:100vh;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
}

.err_logo{
    width:300px;
    height:auto;
}

.err_text{
    color:"black";
    font-size:"60px";
    font-weight:"bolder";
}

@media only screen and (max-width: 600px) {
    .err_main{
        flex-direction: column;
        text-align: center;
    }
}