body {
  /* background: rgb(140, 182, 209); */
  width: 100%;
  height: 100%;
  background: rgb(20,108,113);
background: linear-gradient(0deg, rgba(20,108,113,1) 0%, rgba(45,157,164,1) 34%, rgba(82,212,180,1) 57%, rgb(113, 175, 177) 100%);
}

.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

/* <-------------------------------------OTP CSS-----------------------------------------> */
.timer {
  color: red;
}


/* <----------------------------------DASHBOARD CSS--------------------------------------> */

.user {
  width: 80px;
  height: auto;
}

.upload_button {
  height: 95%;
  margin-left: 10px;
}

.dash-card {
  width: 450px;
  padding: 2em;
  border-radius: 20px;
  color: white;
  line-height: 25px;
  box-shadow: 0px 30px 25px -25px rgba(0, 0, 0, 0.5);
  float: left;
  margin-right: 1em;
}

.card_dash{
  width: 400px;
  height: 230px;
  box-shadow: 0px 30px 25px -25px rgba(0, 0, 0, 0.5);
  border: white 1px solid;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  margin: 60px 0;
  text-align: left;
}

.top {
  position: relative;
  left: -13px;
  border-radius: 25px 25px 0 0;
  height: 100px;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.249);
}

.name {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.793);
  font-weight: bold;
  padding: 30px;
  position: relative;
  display: flex;
  flex: 1;
}

.circle-img {
  border-radius: 50%;
  border: 7px solid #fff;
  position: relative;
  left: 250px;
  bottom: 25px;
  width: 110px;
  z-index: +1;
}

.bottom {
  /* margin-top: -30px; */
  margin-left: 20px;
}

.info {

  margin: 20px 0;
  color: rgba(255, 255, 255, 0.821);
  font-weight: normal;
}

/*DASHBOARD BG FIX*/
section {
  height: 100vh;
  padding: 0;
  background-size: cover;
}

/* .tab{
    overflow-y: scroll;
  } */

.tableBox {
  margin-top: -80px;
}


@media only screen and (max-width: 600px) {
  .logo_medbase_login{
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .but-space{
    margin-top: 5px;
  }
}

.padding-fix{
  padding-top: 20px;
  padding-bottom: 120px;
}

.no_data{
  background-color: rgb(248, 249, 250);
  text-align: center;
  color: grey;
  font-size: 25px;
  font-weight: 500;
  padding-top: 20px;
}

.tableBox_b {
  background-color: rgb(248, 249, 250);
  border-radius: 0px 0px 20px 20px;
  height: 0px;
  padding-bottom: 20px;
}